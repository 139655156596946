import * as Yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { cnpj as cnpjValidator, cpf as cpfValidator } from 'cpf-cnpj-validator';

Yup.setLocale(ptForm);

// const Kilobytes = 1024;
// const Megabytes = 1024 * Kilobytes;

function cpf(message = 'Digite um CPF válido') {
  return this.test({
    name: 'cpf',
    exclusive: false,
    message,
    test: function (value) {
      return value ? cpfValidator.isValid(value) : false;
    },
  });
}
Yup.addMethod(Yup.string, 'cpf', cpf);

function cnpj(message = 'Digite um CNPJ válido') {
  return this.test({
    name: 'cnpj',
    exclusive: false,
    message,
    test: function (value) {
      return value ? cnpjValidator.isValid(value) : false;
    },
  });
}
Yup.addMethod(Yup.string, 'cnpj', cnpj);

function name(
  minLength = 6,
  maxLength = 128,
  message = 'Digite um nome válido e sem abreviar.',
) {
  return this.matches(
    new RegExp(`^[a-zà-ûç ]{${minLength},${maxLength}}$`, 'gi'),
    { message },
  );
}
Yup.addMethod(Yup.string, 'name', name);

function fullName(
  message = 'Digite um nome e sobrenome válidos e sem abreviar.',
) {
  return this.test('fullName', message, function (value) {
    if (!value) return false;

    const trimName = value.toLowerCase().trim()

    const regex = /^[a-zà-ûç]{3,}(?:\s[a-zà-ûç]+)+$/i;
    return regex.test(trimName);
  });
}

Yup.addMethod(Yup.string, 'fullName', fullName);

function birthDate(message = 'Digite uma data de nascimento válida') {
  return this.test({
    name: 'birthDate',
    exclusive: false,
    message,
    test: function (value) {
      if (!value) return false;

      const thisYear = new Date().getFullYear();
      const [day, month, year] = value.split('/').map(Number);

      if (!day || !month || !year) return false;
      if (day > 31 || day <= 0) return false;
      if (month > 12 || month <= 0) return false;

      const age = thisYear - year;
      return age >= 14 && age < 100;
    },
  });
}
Yup.addMethod(Yup.string, 'birthDate', birthDate);

// eslint-disable-next-line no-template-curly-in-string
function cellphone(message = '${path} deve ser válido') {
  return this.test({
    name: 'cellphone',
    exclusive: false,
    message,
    test: function (value) {
      const cleanedValue = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
      const lengthValid = [10, 11].includes(cleanedValue.length); // Verifica se o comprimento é 10 ou 11
      const validPrefix = !/^(0)[0-9]/.test(cleanedValue); // Verifica se os dois primeiros dígitos não são 00, 01, ou 10

      return lengthValid && validPrefix && true;
    },
  });
}
Yup.addMethod(Yup.string, 'cellphone', cellphone);

// eslint-disable-next-line no-template-curly-in-string
function email(message = 'Digite um email valido. Ex: seuemail@gmail.com') {
  return this.test({
    name: 'email',
    exclusive: false,
    message,
    test: function (value) {
      return value
        ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+(\.[a-zA-Z]{2,})+$/i.test(value)
        : true;
    },
  });
}
Yup.addMethod(Yup.string, 'email', email);

// eslint-disable-next-line no-template-curly-in-string
function password(message = '${path} deve possuir pelo menos 6 dígitos') {
  return this.test({
    name: 'password',
    exclusive: false,
    message,
    test: function (value) {
      return value ? value.length >= 6 : true;
    },
  });
}
Yup.addMethod(Yup.string, 'password', password);

// eslint-disable-next-line no-template-curly-in-string
function cep(message = '${path} deve ser válido') {
  return this.test({
    name: 'cep',
    exclusive: false,
    message,
    test: function (value) {
      return value ? /^\d{2}\.?\d{3}-?\d{3}$/.test(value) : true;
    },
  });
}
Yup.addMethod(Yup.string, 'cep', cep);

// eslint-disable-next-line no-template-curly-in-string
function imageUploadLessThan2Mb(message = '${path} deve ter no máximo 2 MB') {
  return this.test({
    name: 'imageUploadLessThan2Mb',
    exclusive: false,
    message: field => {
      if (!field.value) return `Escolha uma imagem para ${field.path}.`;
      if (field.value.size > 2097152)
        return `${field.path} deve ter no máximo 2 MB`;
      if (!field.value.cropped)
        return `Escolha uma imagem para ${field.path}..`;
      return `${field.path} deve ter no máximo 2 MB`;
    },
    // import { ImageUploadValue } from './components/image-upload';
    test: imageUploadValue => {
      if (!imageUploadValue) return false;
      if (imageUploadValue.size > 2097152) return false;
      return imageUploadValue.size <= 2097152;
    },
  });
}
Yup.addMethod(Yup.mixed, 'imageUploadLessThan2Mb', imageUploadLessThan2Mb);

// eslint-disable-next-line no-template-curly-in-string
function imageUploadLessThan3Mb(message = '${path} deve ter no máximo 3 MB') {
  return this.test({
    name: 'imageUploadLessThan3Mb',
    exclusive: false,
    message: field => {
      if (!field.value) return `Escolha uma imagem para ${field.path}.`;
      if (field.value.size > 3145728)
        return `${field.path} deve ter no máximo 3 MB`;
      if (!field.value.cropped)
        return `Escolha uma imagem para ${field.path}..`;
      return `${field.path} deve ter no máximo 3 MB`;
    },
    // import { ImageUploadValue } from './components/image-upload';
    test: imageUploadValue => {
      if (!imageUploadValue) return false;
      if (imageUploadValue.size > 3145728) return false;
      return imageUploadValue.size <= 3145728;
    },
  });
}
Yup.addMethod(Yup.mixed, 'imageUploadLessThan3Mb', imageUploadLessThan3Mb);

function imageUploadOptionalLessThan2Mb(
  // eslint-disable-next-line no-template-curly-in-string
  message = '${path} deve ter no máximo 2 MB',
) {
  return this.test({
    name: 'imageUploadOptionalLessThan2Mb',
    exclusive: false,
    message: field => {
      return `${field.path} deve ter no máximo 2 MB`;
    },
    // import { ImageUploadValue } from './components/image-upload';
    test: imageUploadValue => {
      if (imageUploadValue && imageUploadValue.size > 2097152) return false;
      return true;
    },
  });
}
Yup.addMethod(
  Yup.mixed,
  'imageUploadOptionalLessThan2Mb',
  imageUploadOptionalLessThan2Mb,
);

// eslint-disable-next-line no-template-curly-in-string
function multipleFilesUpload(message = '${path} deve ter no máximo 2 MB') {
  return this.test({
    name: 'multipleFilesUpload',
    exclusive: false,
    message: message,
    // import { ImageUploadValue } from './components/image-upload';
    test: fileUploadValue => {
      if (fileUploadValue.length > 5) return false;
    },
  });
}
Yup.addMethod(Yup.mixed, 'multipleFilesUpload', multipleFilesUpload);

function quillRichEditor(message = 'O campo é obrigatório.') {
  return this.test({
    name: 'quillRichEditor',
    exclusive: false,
    message: message,
    test: function (value) {
      return !!value.ops?.[0]?.insert && value.ops?.[0]?.insert !== '\n';
    },
  });
}
Yup.addMethod(Yup.object, 'quillRichEditor', quillRichEditor);

// eslint-disable-next-line no-template-curly-in-string
function imageUploadLessThan1Mb(message = '${path} deve ter no máximo 1 MB') {
  return this.test({
    name: 'imageUploadLessThan1Mb',
    exclusive: false,
    message: field => {
      if (!field.value) return `Escolha uma imagem para ${field.path}.`;
      if (field.value.size > 1048576)
        return `${field.path} deve ter no máximo 1 MB`;
      if (!field.value.cropped)
        return `Escolha uma imagem para ${field.path}..`;
      return `${field.path} deve ter no máximo 1 MB`;
    },
    // import { ImageUploadValue } from './components/image-upload';
    test: imageUploadValue => {
      if (!imageUploadValue) return false;
      if (imageUploadValue.size > 1048576) return false;
      return imageUploadValue.size <= 1048576;
    },
  });
}
Yup.addMethod(Yup.mixed, 'imageUploadLessThan1Mb', imageUploadLessThan1Mb);

// eslint-disable-next-line no-template-curly-in-string
function imageUploadOptionalLessThan1Mb(
  // eslint-disable-next-line no-template-curly-in-string
  message = '${path} deve ter no máximo 1 MB',
) {
  return this.test({
    name: 'imageUploadOptionalLessThan1Mb',
    exclusive: false,
    message: field => {
      return `${field.path} deve ter no máximo 1 MB`;
    },
    // import { ImageUploadValue } from './components/image-upload';
    test: imageUploadValue => {
      if (imageUploadValue && imageUploadValue.size > 1048576) return false;
      return true;
    },
  });
}
Yup.addMethod(
  Yup.mixed,
  'imageUploadOptionalLessThan1Mb',
  imageUploadOptionalLessThan1Mb,
);

function imageUploadOptionalLessThan3Mb(
  // eslint-disable-next-line no-template-curly-in-string
  message = '${path} deve ter no máximo 3 MB',
) {
  return this.test({
    name: 'imageUploadOptionalLessThan3Mb',
    exclusive: false,
    message: field => {
      return `${field.path} deve ter no máximo 3 MB`;
    },
    // import { ImageUploadValue } from './components/image-upload';
    test: imageUploadValue => {
      if (imageUploadValue && imageUploadValue.size > 3145728) return false;
      return true;
    },
  });
}
Yup.addMethod(
  Yup.mixed,
  'imageUploadOptionalLessThan3Mb',
  imageUploadOptionalLessThan3Mb,
);

export const yup = Yup;

export { yupResolver } from '@hookform/resolvers/yup';

export const mergeRefs = refs => {
  return value => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        ref.current = value;
      }
    });
  };
};
