import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { ClientJS } from 'clientjs';
import { Buffer } from 'buffer';


import { Env, Storages } from 'src/appConstants';
import { useAuthModal } from 'src/modules/auth-modal';
import { useUserStore } from 'src/hooks/stores';

// TODO: remove after fully remove legacy code (where retryOnAuth is called when using axios)
type LegacyAxiosError = AxiosError & { config: { retryOnAuth: boolean } };

const client = new ClientJS();

const getClientUUID = () => {
  if (!window.localStorage.getItem(Storages.CLIENT_UUID)) {
    window.localStorage.setItem(Storages.CLIENT_UUID, uuidv4());
  }
  return window.localStorage.getItem(Storages.CLIENT_UUID);
};

const openAuthModal = (error: LegacyAxiosError) => {
  return (
    new Promise(resolve => {
      // @ts-ignore
      useAuthModal.setState(prev => ({
        isOpen: true,
        resolvers: error.config.retryOnAuth
          ? [...prev.resolvers, resolve]
          : prev.resolvers,
      }));
    })
      // @ts-ignore
      .then(({ email, token }) => {
        useAuthModal.setState({ isOpen: false, resolvers: [] });
        const credentials = `${email}:${token}`;
        const encodedCredentials = Buffer.from(credentials).toString('base64');
        error.config.headers.Authorization = `Basic ${encodedCredentials}`;
        return axios.request(error.config);
      })
      .catch(error => Promise.reject(error.response))
  );
};

/////////////////////////////////////////////////////////////////////

export const requestFullfilledAuthHeader = (config: AxiosRequestConfig) => {
  const { id, accessToken, token, email } = useUserStore.getState();
  if (!accessToken) return config;
  if (Env === 'development') {
    config.headers['X-Token'] = token;
    config.headers['X-User-Id'] = id;
    config.headers['X-User-Email'] = email;
    config.headers['X-User-Level'] = 1;
  } else {
    config.headers.Authorization = `Basic ${accessToken}`;
  }
  return config;
};

export const requestFullfilledCustomHeaders = (config: AxiosRequestConfig) => {
  config.headers = {
    ...config.headers,
    'X-Correlation-ID': getClientUUID(),
    ...(Env === 'development'
      ? {}
      : { 'X-Fingerprint': client.getFingerprint() }),
  };
  return config;
};

export const responseFullfilled = (response: AxiosResponse) => response;

export const responseBlob = (response: AxiosResponse) =>
  new Blob([response.data], {
    type: response.headers['content-type'],
  });

// TODO: switch after full migration to react-query.
// export const responseRejectedNotFound = error => {
//   if (error.response?.status === 401) {
//     return new Promise(dummyResolver => {
//       useAuthModal.setState({ isOpen: true, dummyResolver });
//     });
//   }
//   return error;
// };
export const responseRejectedNotFound = (error: LegacyAxiosError) => {
  if (error.response?.status === 401) {
    return openAuthModal(error);
  }
  return error;
};

export const responseRejected = (error: LegacyAxiosError) => {
  // at this point, the error can be a custom error handler (eg.: 401) or not
  if (error.isAxiosError) return Promise.reject(error.response);
  return Promise.resolve(error);
};

export const responseArrayBuffer = (error: LegacyAxiosError) => {
  if (error.response?.data instanceof ArrayBuffer) {
    const decoder = new TextDecoder('utf-8');
    error.response.data = JSON.parse(decoder.decode(error.response.data));
  }
  return error;
};

type ApiError = {
  code: string;
  message: string;
  status: number;
};

export const getApiError = (error: any) => {
  try {
    return error?.data as ApiError;
  } catch {
    return {
      code: 'unexpected',
      message: 'Ocorreu um erro inesperado',
      status: 500,
    };
  }
};
